export const routes = [
    {
        path: '/products',
        name: 'products.browse',
        component: () => import(/* webpackChunkName: "ProductsBrowse" */ '@/views/app/ProductsBrowse.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/products/:id/clone',
        name: 'products.clone',
        component: () => import(/* webpackChunkName: "ProductsActions" */ '@/views/app/ProductsActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/products/create',
        name: 'products.create',
        component: () => import(/* webpackChunkName: "ProductsActions" */ '@/views/app/ProductsActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/products/:id/edit',
        name: 'products.edit',
        component: () => import(/* webpackChunkName: "ProductsActions" */ '@/views/app/ProductsActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/products/:id/delete',
        name: 'products.delete',
        component: () => import(/* webpackChunkName: "ProductsActions" */ '@/views/app/ProductsActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup" }
    },
    {
        path: '/products/:id/show',
        name: 'products.show',
        component: () => import(/* webpackChunkName: "ProductsActions" */ '@/views/app/ProductsActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
]